<template>
	<v-container fluid>
		<router-view :key="$route.fullPath" class="mypage" />
	</v-container>
</template>
<script>
import ChannelService from '@/services/ChannelService'

export default {
	components: {},
	setup() {
		ChannelService.boot()
	},
}
</script>
<style lang="scss" scoped>
.mypage {
	max-width: 1100px;
	margin: 0 auto;
}

.v-progress-linear {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}
button {
	::v-deep .v-btn__content {
		color: #ffffff;
	}
}
</style>
