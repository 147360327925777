import store from '@/store'
import { computed } from '@vue/composition-api'

const PLUGIN_KEY = process.env.VUE_APP_CHANNEL_TALK_PLUGIN_KEY

function loadScript() {
	const w = window
	if (w.ChannelIO) {
		return (window.console.error || window.console.log || function () {})(
			'ChannelIO script included twice.',
		)
	}
	const ch = function () {
		ch.c(arguments)
	}
	ch.q = []
	ch.c = function (args) {
		ch.q.push(args)
	}
	w.ChannelIO = ch
	function l() {
		if (w.ChannelIOInitialized) {
			return
		}
		w.ChannelIOInitialized = true
		const s = document.createElement('script')
		s.type = 'text/javascript'
		s.async = true
		s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
		s.charset = 'UTF-8'
		const x = document.getElementsByTagName('script')[0]
		x.parentNode.insertBefore(s, x)
	}
	if (document.readyState === 'complete') {
		l()
	} else if (window.attachEvent) {
		window.attachEvent('onload', l)
	} else {
		window.addEventListener('DOMContentLoaded', l, false)
		window.addEventListener('load', l, false)
	}
}

async function boot() {
	const settings = {
		pluginKey: PLUGIN_KEY,
		memberId: '',
		profile: {},
	}
	if (store.getters['auth/isSignin']) {
		const profile = computed(() => store.getters['user/getMeDetail'])
		settings.memberId = profile.value?.id
		settings.profile.name = profile.value?.name
		settings.profile.mobileNumber = profile.value?.phoneNumber
	}
	window.ChannelIO('boot', settings)
}

function shutdown() {
	window.ChannelIO('shutdown')
}

loadScript()

export default {
	boot,
	shutdown,
}
